import './App.css';
import {ReactComponent as LiLogo} from './logos/linkedin.svg';
import {ReactComponent as GhLogo} from './logos/github.svg';
import {ReactComponent as EmailLogo} from './logos/email.svg';

function App() {
  return (
    <>
    <div className="App">
      <header className="App-header">
      <h1 className='Body-header'>hi, my name is yoji watanabe</h1>
      <div className='Body-text'>
        <AboutInfo />
        < BlinkingCursor />
      </div>
      </header>
      <Footer />
    </div>
    </>
  );
}

function AboutInfo() {
  return (
    <>
      <p>i'm currently at <a href="https://www.fireblocks.com/">FIREBLOCKS</a> sharpening financial services providers' blockchain operations. at fireblocks, i assist t1 clients with their blockchain projects, build tooling, and write code.</p>
      <p>previously, i was a tech lead working with federal and state governments, implementing and operating distributed health record systems, and a security engineer in higher education implementing oss security tools.</p>
    </>
  );
}

/*
Alt:
      <p>at fireblocks, i:<br />
      - consult with g-sib banks on custody, tokenization, and blockchain settlement projects<br />
      - review and remediate security concerns in internal and t1 financial institutions' blockchain operations<br />
      - delivered successful proof-of-concept solidity contracts for inter-bank settlements, securing a $1MM contract<br />
      - assist our most important clients with their most pressing technical needs
      </p>
*/


const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className='footer'>
      <viewbox>
        <a className='footer-logos' href='https://www.linkedin.com/in/yojiwatanabe/'><LiLogo /></a>
        <a className='footer-logos' href='https://github.com/yojiwatanabe'><GhLogo /></a>
        <a className='footer-logos' href='mailto:yojiwata@gmail.com?subject=SiteContact'><EmailLogo /></a>
      <p>{`Copyright © Yoji Hamada Watanabe ${year}`}</p>
      </viewbox>
    </footer>
  )
};

function BlinkingCursor() {
  return(
    <span class="blinking-cursor">|</span>
  )
}

export default App;
